import React from 'react';
import { Link } from 'react-router-dom';
import { Analytics } from "@vercel/analytics/react";
import {
  AppBar,
  Toolbar,
  Typography,
  Container,
  Button,
  Box,
  Grid,
  Card,
  CardContent,
  useTheme,
  useMediaQuery,
  Zoom,
  Grow,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItem,
  ListItemText
} from '@mui/material';
import { styled, keyframes } from '@mui/system';
import {
  Description as DescriptionIcon,
  Search as SearchIcon,
  Email as EmailIcon,
  ExpandMore as ExpandMoreIcon
} from '@mui/icons-material';

// Define animations
const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const slideIn = keyframes`
  from { transform: translateY(50px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
`;

const pulse = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
`;

const rotateIn = keyframes`
  from { transform: rotateY(90deg); opacity: 0; }
  to { transform: rotateY(0); opacity: 1; }
`;

// Styled components
const AnimatedBox = styled(Box)(({ theme }) => ({
  animation: `${fadeIn} 1s ease-out`
}));

const AnimatedCard = styled(Card)(({ theme, delay }) => ({
  animation: `${slideIn} 1s ease-out ${delay}s both, ${pulse} 2s ease-in-out ${delay + 1}s infinite`,
  backgroundColor: theme.palette.background.paper,
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: theme.shadows[10],
  },
}));

const AnimatedIcon = styled(Box)(({ theme, delay }) => ({
  animation: `${rotateIn} 1s ease-out ${delay}s both`
}));

const HeroSection = styled(Box)(({ theme }) => ({
  backgroundImage: 'linear-gradient(120deg, #4B0082 0%, #800080 100%)',
  padding: theme.spacing(15, 0),
  textAlign: 'center',
  color: theme.palette.common.white,
  position: 'relative',
  overflow: 'hidden',
  '&::after': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'radial-gradient(circle, transparent 20%, #ffffff10 20%, #ffffff10 80%, transparent 80%, transparent)',
    backgroundSize: '50px 50px',
    animation: `${fadeIn} 2s ease-out`,
    pointerEvents: 'none'
  }
}));

const AnimatedButton = styled(Button)(({ theme }) => ({
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: theme.shadows[4]
  }
}));

const PricingCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-10px)',
    boxShadow: theme.shadows[10],
  },
}));

const PricingCardContent = styled(CardContent)({
  flexGrow: 1,
});

function App() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const features = [
    { title: 'Parse Resumes', icon: DescriptionIcon, description: 'Quickly extract key information from resumes.' },
    { title: 'Job Description Match', icon: SearchIcon, description: 'Find resumes that match your job requirements.' },
    { title: 'Instant Feedback', icon: EmailIcon, description: 'Get personalized feedback sent directly to your email.' }
  ];

  const pricingOptions = [
    {
      title: 'Single Purchase',
      price: '$25',
      features: [
        '50 resume analyses',
        'Basic job matching',
        'Email support',
        'Feedback Email Report',
        'Valid for 30 days'
      ],
      buttonText: 'Buy Now',
      buttonLink: 'https://resos.lemonsqueezy.com/buy/c32d53ee-722b-42dd-aaaf-ec32c8a27294' // Replace with your actual purchase link
    },
    {
      title: 'Monthly Subscription',
      price: '$100/month',
      features: [
        'Unlimited resume analyses',
        'Advanced job matching',
        'LinkedIn integration',
        'Priority email support',
        'API access',
        'All Single Purchase features',
        'Cancel anytime'
      ],
      buttonText: 'Subscribe',
      buttonLink: 'https://payment.intasend.com/subscriptions/charge/4KZ4VYP/plan/' // Your subscription payment link
    }
  ];

  const faqItems = [
    {
      question: 'What are the terms of service?',
      answer: 'Our terms of service include responsible use of the platform, respecting user privacy, and adhering to data protection regulations. For full details, please visit our Terms of Service page.'
    },
    {
      question: 'What is your refund policy?',
      answer: 'We offer a 7-day refund policy for both our single purchase and subscription plans. If you\'re not satisfied with our service, you can request a full refund within 7 days of your purchase.'
    },
    {
      question: 'How does the resume parsing work?',
      answer: 'Our AI-powered system analyzes resumes to extract key information such as skills, experience, and education. It then organizes this data in a structured format for easy review.'
    },
    {
      question: 'Is my data secure?',
      answer: 'Yes, we take data security very seriously. All uploaded resumes and personal information are encrypted and stored securely. We never share your data with third parties.'
    }
  ];

  return (
    <>
      <AppBar position="static" color="transparent" elevation={0}>
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            RESO AI
          </Typography>
          <AnimatedButton color="inherit" component={Link} to="https://resos.lemonsqueezy.com/buy/c32d53ee-722b-42dd-aaaf-ec32c8a27294">Try Now</AnimatedButton>
        </Toolbar>
      </AppBar>

      <HeroSection>
        <Container>
          <Zoom in={true} style={{ transitionDelay: '300ms' }}>
            <Typography variant={isMobile ? 'h3' : 'h2'} component="h1" gutterBottom>
              Revolutionize Your Hiring Process
            </Typography>
          </Zoom>
          <Zoom in={true} style={{ transitionDelay: '600ms' }}>
            <Typography variant="h5" component="h2" gutterBottom>
              AI-Powered Resume Analysis and Feedback
            </Typography>
          </Zoom>
          <Grow in={true} style={{ transitionDelay: '900ms' }}>
            <AnimatedButton 
              variant="contained" 
              size="large" 
              component={Link} 
              to="https://resos.lemonsqueezy.com/buy/c32d53ee-722b-42dd-aaaf-ec32c8a27294"
              sx={{ mt: 4, fontSize: '1.2rem', padding: '10px 30px' }}
            >
              Try Now
            </AnimatedButton>
          </Grow>
        </Container>
      </HeroSection>

      <Container sx={{ my: 8 }}>
        <Grid container spacing={4}>
          {features.map((feature, index) => (
            <Grid item xs={12} md={4} key={feature.title}>
              <AnimatedCard delay={0.2 * (index + 1)}>
                <CardContent sx={{ textAlign: 'center' }}>
                  <AnimatedIcon delay={0.5 * (index + 1)}>
                    {React.createElement(feature.icon, { sx: { fontSize: 60, color: theme.palette.primary.main } })}
                  </AnimatedIcon>
                  <Typography variant="h5" component="div" sx={{ mt: 2 }}>
                    {feature.title}
                  </Typography>
                  <Typography variant="body1" color="text.secondary" sx={{ mt: 1 }}>
                    {feature.description}
                  </Typography>
                </CardContent>
              </AnimatedCard>
            </Grid>
          ))}
        </Grid>
      </Container>

      <Container sx={{ my: 8 }}>
        <Typography variant="h3" component="h2" gutterBottom align="center">
          Choose Your Plan
        </Typography>
        <Grid container spacing={4}>
          {pricingOptions.map((option, index) => (
            <Grid item xs={12} md={6} key={option.title}>
              <PricingCard>
                <PricingCardContent>
                  <Typography variant="h4" component="div" gutterBottom>
                    {option.title}
                  </Typography>
                  <Typography variant="h3" component="div" color="primary" gutterBottom>
                    {option.price}
                  </Typography>
                  <List>
                    {option.features.map((feature, featureIndex) => (
                      <ListItem key={featureIndex}>
                        <ListItemText primary={feature} />
                      </ListItem>
                    ))}
                  </List>
                </PricingCardContent>
                <Box sx={{ p: 2 }}>
                  <Button
                    variant="contained"
                    fullWidth
                    component="a"
                    href={option.buttonLink} // Updated to use the payment link
                    target="_blank"
                  >
                    {option.buttonText}
                  </Button>
                </Box>
              </PricingCard>
            </Grid>
          ))}
        </Grid>
      </Container>

      <Container sx={{ my: 8 }}>
        <Typography variant="h3" component="h2" gutterBottom align="center">
          Frequently Asked Questions
        </Typography>
        {faqItems.map((item, index) => (
          <Accordion key={index}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6">{item.question}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{item.answer}</Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Container>

      <Box sx={{ bgcolor: 'background.paper', py: 6 }} component="footer">
        <Typography variant="body2" color="text.secondary" align="center">
          © {new Date().getFullYear()} RESO AI. All rights reserved.
        </Typography>
      </Box>

      <Analytics />
    </>
  );
}

export default App;
