import React, { useState } from 'react';
import { Analytics } from '@vercel/analytics/react';
import axios from 'axios';
import {
  Button,
  TextField,
  Container,
  Typography,
  Box,
  Card,
  CardContent,
  CircularProgress,
  Fade,
  Grow,
  Slide,
  Alert,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  ThemeProvider,
  createTheme,
  CssBaseline,
} from '@mui/material';
import { styled, keyframes } from '@mui/system';
import { CloudUpload as CloudUploadIcon, MonetizationOn as MonetizationOnIcon } from '@mui/icons-material';

// Create a dark theme
const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#BB86FC',
    },
    secondary: {
      main: '#03DAC6',
    },
    background: {
      default: '#121212',
      paper: '#1E1E1E',
    },
  },
});

// Product Hunt Badge
const ProductHuntBadge = styled('a')({
  display: 'block',
  textAlign: 'center',
  marginBottom: '20px',
});

const glowAnimation = keyframes`
  0% { box-shadow: 0 0 5px #BB86FC; }
  50% { box-shadow: 0 0 20px #BB86FC; }
  100% { box-shadow: 0 0 5px #BB86FC; }
`;

const AnimatedBanner = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  textAlign: 'center',
  animation: `${glowAnimation} 2s infinite`,
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  zIndex: 1000,
  cursor: 'pointer',
  backgroundColor: theme.palette.background.paper,
}));

const BackgroundContainer = styled(Container)(({ theme }) => ({
  backgroundImage: 'linear-gradient(120deg, #4B0082 0%, #800080 100%)',
  minHeight: '100vh',
  padding: theme.spacing(4),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: theme.spacing(1),
}));

const StyledCard = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: theme.shadows[10],
  },
}));

function Resume() {
  const [resumeFile, setResumeFile] = useState(null);
  const [jobDescription, setJobDescription] = useState('');
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [openDonateDialog, setOpenDonateDialog] = useState(false);

  const handleResumeChange = (e) => {
    setResumeFile(e.target.files[0]);
  };

  const handleJobDescriptionChange = (e) => {
    setJobDescription(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!resumeFile || !jobDescription) {
      setError('Please upload a resume and provide a job description.');
      return;
    }

    const formData = new FormData();
    formData.append('resume', resumeFile);
    formData.append('jobDescription', jobDescription);

    setLoading(true);
    setError(null);

    try {
      const response = await axios.post('https://test-2oux.onrender.com/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setResult({
        subject: response.data.emailSubject,
        body: response.data.emailBody,
      });
    } catch (err) {
      setError('Error uploading file or processing request.');
    } finally {
      setLoading(false);
    }
  };

  const handleOpenDonateDialog = () => {
    setOpenDonateDialog(true);
  };

  const handleCloseDonateDialog = () => {
    setOpenDonateDialog(false);
  };

  const handleCopyAddress = () => {
    navigator.clipboard.writeText('0xf7981432b640C5da6Fa8AeA0F8e800D102Ba32B5');
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Analytics />
      <BackgroundContainer maxWidth="md">
        <ProductHuntBadge href="https://www.producthunt.com/posts/reso-ai?embed=true&utm_source=badge-featured&utm_medium=badge&utm_souce=badge-reso&#0045;ai" target="_blank">
          <img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=483487&theme=dark" alt="RESO AI - Instant Resume Insights, Effortlessly | Product Hunt" style={{ width: '250px', height: '54px' }} />
        </ProductHuntBadge>
        <Box my={4}>
          <Typography variant="h4" component="h1" gutterBottom align="center" color="primary">
            RESO - Resume Analysis + Feedback Tool
          </Typography>
          <StyledCard>
            <CardContent>
              <form onSubmit={handleSubmit}>
                <Fade in={true} timeout={1000}>
                  <Box mb={2}>
                    <Button
                      variant="contained"
                      component="label"
                      startIcon={<CloudUploadIcon />}
                      fullWidth
                    >
                      Upload Resume
                      <input
                        type="file"
                        hidden
                        accept=".pdf"
                        onChange={handleResumeChange}
                      />
                    </Button>
                    {resumeFile && <Typography variant="body2" align="center" mt={1}>{resumeFile.name}</Typography>}
                  </Box>
                </Fade>
                <Grow in={true} timeout={1000}>
                  <TextField
                    fullWidth
                    multiline
                    rows={5}
                    variant="outlined"
                    label="Job Description"
                    value={jobDescription}
                    onChange={handleJobDescriptionChange}
                    margin="normal"
                  />
                </Grow>
                <Box mt={2}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="secondary"
                    fullWidth
                    disabled={loading}
                    size="large"
                  >
                    {loading ? <CircularProgress size={24} /> : 'Analyze'}
                  </Button>
                </Box>
              </form>
            </CardContent>
          </StyledCard>
          {error && (
            <Slide direction="up" in={true} mountOnEnter unmountOnExit>
              <Alert severity="error" sx={{ mt: 2 }}>{error}</Alert>
            </Slide>
          )}
          {result && (
            <Grow in={true} timeout={1000}>
              <StyledCard sx={{ mt: 4 }}>
                <CardContent>
                  <Typography variant="h6" gutterBottom color="primary">
                    Email Subject:
                  </Typography>
                  <Typography variant="body1" paragraph>
                    {result.subject}
                  </Typography>
                  <Typography variant="h6" gutterBottom color="primary">
                    Email Body:
                  </Typography>
                  <TextField
                    fullWidth
                    multiline
                    rows={10}
                    variant="outlined"
                    value={result.body}
                    InputProps={{ readOnly: true }}
                  />
                  <Button
                    onClick={() => navigator.clipboard.writeText(result.body)}
                    variant="outlined"
                    sx={{ mt: 2 }}
                    fullWidth
                  >
                    Copy to Clipboard
                  </Button>
                </CardContent>
              </StyledCard>
            </Grow>
          )}
          {/* Add Subscription Button */}
          <Box mt={4}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              component="a"
              href="https://payment.intasend.com/subscriptions/charge/4KZ4VYP/plan/" // Replace with your subscription link
              target="_blank"
            >
              Subscribe for More Features
            </Button>
          </Box>
        </Box>
        <AnimatedBanner onClick={handleOpenDonateDialog}>
          <Typography variant="body2">
            Click here to donate CELO CRYPTO
          </Typography>
        </AnimatedBanner>
        <Dialog open={openDonateDialog} onClose={handleCloseDonateDialog}>
          <DialogTitle>Donate CELO CRYPTO</DialogTitle>
          <DialogContent>
            <Typography variant="body1" gutterBottom>
              Your support helps us maintain and improve this service. Please send your CELO donations to:
            </Typography>
            <TextField
              fullWidth
              value="0xf7981432b640C5da6Fa8AeA0F8e800D102Ba32B5"
              InputProps={{ readOnly: true }}
              margin="normal"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCopyAddress} startIcon={<MonetizationOnIcon />}>
              Copy Address
            </Button>
            <Button onClick={handleCloseDonateDialog}>Close</Button>
          </DialogActions>
        </Dialog>
      </BackgroundContainer>
    </ThemeProvider>
  );
}

export default Resume;
